// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-f-a-qs-for-enrolled-participants-js": () => import("./../../../src/pages/fAQsForEnrolledParticipants.js" /* webpackChunkName: "component---src-pages-f-a-qs-for-enrolled-participants-js" */),
  "component---src-pages-f-a-qs-js": () => import("./../../../src/pages/fAQs.js" /* webpackChunkName: "component---src-pages-f-a-qs-js" */),
  "component---src-pages-for-physicians-js": () => import("./../../../src/pages/forPhysicians.js" /* webpackChunkName: "component---src-pages-for-physicians-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meet-our-team-js": () => import("./../../../src/pages/meetOurTeam.js" /* webpackChunkName: "component---src-pages-meet-our-team-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-resource-links-js": () => import("./../../../src/pages/resourceLinks.js" /* webpackChunkName: "component---src-pages-resource-links-js" */)
}

